import React, { useState, useEffect, useRef  } from 'react';
import { useParams, Link } from 'react-router-dom';
import PostInUserProfile from './PostInUserProfile';
import {renderText} from '../utils/utils';
import {DownloadPart} from '../utils/utils';
import { websiteURL, SERVER_URL } from '../utils/consts';
import { Helmet } from 'react-helmet';
import ProfileModal from './ProfileModal';

const MAX_PAGINATIONS = 3;

function LeftColumn({ leftColumnPostsData, renderPost }) {
    if (leftColumnPostsData.length !== 0) {
        console.log(leftColumnPostsData);
      return (
        <div style={{flex: 1}}>
          {leftColumnPostsData.map((item) => (
            <div key={item.id}>
              {renderPost(item)}
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
  
function RightColumn({ rightColumnPostsData, renderPost }) {
    if (rightColumnPostsData.length !== 0) {
        console.log(rightColumnPostsData);

      return (

        <div style={{flex: 1}}>
          {rightColumnPostsData.map((item) => (
            <div key={item.id}>
              {renderPost(item)}
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }

function AllPosts({
    leftColumnPostsData,
    rightColumnPostsData,
    renderPost,
  }) {
    return (
      <div style={{  display: 'flex'}}>
        <LeftColumn
          leftColumnPostsData={leftColumnPostsData}
          renderPost={renderPost}
        />

        <RightColumn
          rightColumnPostsData={rightColumnPostsData}
          renderPost={renderPost}
        />

      </div>
    );
  }
  

function getPublicUserData(userName) {
    return new Promise(async (resolve, reject) => { 
      const formData = new FormData();
      formData.append('target_user_name', userName);
      try {
        const response = await fetch(SERVER_URL + '/get_public_user_data_by_user_name/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: formData,
        });
  
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          resolve(data);
        } else {
          reject(new Error('Failed to fetch public user data'));
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  
const UserDetailsPage = () => {
    const { userName } = useParams();
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);
    const [userDisplayName, setUserDisplayName] = useState('');
    const [userProfileImage, setUserProfileImage] = useState('');
    const [userId, setUserId] = useState(null);
    const [countOfFollowers, setCountOfFollowers] = useState(0);
    const [userBio, setUserBio] = useState('');
    const [isFollowing, setIsFollowing] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [leftColumnPostsData, setLeftColumnPostsData] = useState([]);
    const [rightColumnPostsData, setRightColumnPostsData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const isLoading = useRef(false);     
      const renderPost = (item) => {
        if (item && item.id) {
            const url = "/posts/" + item.id * 409863; 
          return (
            <div>
                <Link to={url}>
                <PostInUserProfile item={item} />

                </Link>

            </div>
          );
        }
      };
      
      const fetchRecentPosts = async (userId) => {
        try {
          if (isLoading.current) return;
          isLoading.current = true;
          for (let page = 1; page <= MAX_PAGINATIONS; page++) {
            const response = await fetch(SERVER_URL+`/get_users_post_articles/user/${userId}/page/${page}`);
            const postsData = await response.json();
            const updatedLeftColumnPosts = [];
            const updatedRightColumnPosts = [];
            for (const post of postsData) {
              const leftColumnHeight = getColumnHeight(updatedLeftColumnPosts);
              const rightColumnHeight = getColumnHeight(updatedRightColumnPosts);
              if (leftColumnHeight > rightColumnHeight) {
                post.column = 'right';
                post.new = true;
                updatedRightColumnPosts.push(post);
                setRightColumnPostsData((prevPosts) => [...prevPosts, post]);
              } else {
                post.column = 'left';
                post.new = true;
                updatedLeftColumnPosts.push(post);
                setLeftColumnPostsData((prevPosts) => [...prevPosts, post]);
              }
            }
            if (postsData.length === 0) {
              return
            }
          }

        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
            isLoading.current = false;
        }
      };
      useEffect(() => {
        getPublicUserData(userName)
          .then(data => {
            console.log(data);
            let bio = data['bio'];
            let name = data['display_name'];
            let userId = data['id'];
            let followersCount = data['followers_count'];
            let following = data['is_following'];
            let blocked = data['is_blocked'];
    
            if (name) {
              setUserDisplayName(name);
              setUserId(userId);
              setCountOfFollowers(followersCount);
              setUserBio(bio);
              setIsFollowing(following);
              setUserProfileImage(SERVER_URL + '/profile_image/'+userId);
              setIsBlocked(blocked);
              fetchRecentPosts(userId);

            }
          })
          .catch(error => {
            console.error('Error fetching public user data:', error);
          });
      }, [userName]);

      const getColumnHeight = (column) => {
        let heightSum = 0;
        for (const post of column) {
          let additionalHeightFromTitle = 0;
          const postTitle = post.post_title;
          if (postTitle && postTitle.length > 30) {
            const approximateNewLines = Math.floor(postTitle.length / 27);
            additionalHeightFromTitle = approximateNewLines * 10;
          }
          heightSum += post.height + additionalHeightFromTitle;
        }
        return heightSum;
      };
    
  return (
    <div className="user-page">
    <Helmet>
      <title>{userDisplayName} - Coperinno</title>
      {userBio &&  <meta name="description" content={userBio.slice(0, 200)} />}
      <link rel="canonical" href={websiteURL+'/user/' + userName} />
    </Helmet>
    <div
      style={{
        flex: 1,
        marginTop: window.innerHeight * 0.04,
        left: window.innerHeight * 0.05,
        alignItems: 'flex-start',
        marginLeft: 40
      }}
    >
      { userProfileImage ? ( <img
        style={{
          width: window.innerHeight * 0.10,
          height: window.innerHeight * 0.10,
          borderRadius: window.innerHeight * 0.10,
          marginBottom: 10,
          objectFit: 'cover',
          borderWidth: 0.5,
          borderColor: 'black',
        }}
        src={userProfileImage ? userProfileImage : null}
        onError={(e) => { e.target.style.display = 'none'; }}
        alt="User Profile"
      />) : null}
      <h1
        style={{
        fontFamily: 'sans-serif',
        fontSize: 25,
          fontWeight: 'bold',
          marginBottom: 5,
          
        }}
      >
        {userDisplayName}
      </h1>
      <div style={{ marginBottom: 20 }}>
        <p style={{fontFamily: 'sans-serif' }}>@{userName}</p>
        <div>
        {userBio && renderText(userBio)}
        </div>
      </div>

    </div>
    
    {/* <div style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
      <b style={{ fontFamily: 'sans-serif' }}>
        {countOfFollowers || 0}
      </b>
      <span style={{ fontFamily: 'sans-serif' }}>
        Followers
      </span>
    </div> */}
    <div style={{justifyContent: 'center', display: 'flex', marginBottom:'150px'}}>
    <AllPosts
        leftColumnPostsData={leftColumnPostsData}
        rightColumnPostsData={rightColumnPostsData}
        renderPost={renderPost}
      />
    </div>
    {userName && <ProfileModal open={open} handleClose={handleClose} userName={userName} userImage={userProfileImage}/>}
    <DownloadPart> </DownloadPart>
    </div>
  );
};

export default UserDetailsPage;
