



export default function PrivacyPolicy() {
    return(
        <div>
            <p>
            Effective: 2023

We at Coperinno understand the importance of protecting your privacy and are committed to ensuring the confidentiality and security of your personal information. This Privacy Consent Document outlines the data we collect, how we use it, and the choices you have regarding your personal information when using our mobile application ("Coperinno App").

By using the Coperinno App, you consent to the collection, use, and disclosure of your personal information as described in this document. Please read this document carefully to understand our practices regarding your personal information.

1. Information We Collect:
When you use the Coperinno App, we may collect the following information:

a) User-Provided Information: When you create an account or interact with the Coperinno App, we may collect personal information such as your name, email address, username, and password.

b) Usage Information: We collect information about your use of the Coperinno App, including the posts you view, like, and comment on, the users you follow, and your activity within the application.

c) Device Information: We may collect information about the device you use to access the Coperinno App, including your device type, operating system, unique device identifiers, and mobile network information.

d) Log Information: Our servers automatically collect certain information when you use the Coperinno App, including your IP address, browser type, pages visited, and the date and time of your visit.

2. Use of Information:
We use the collected information for the following purposes:

a) Providing and Improving the Coperinno App: We use the information to provide and enhance your user experience, personalize your content recommendations, and improve the functionality and features of the Coperinno App.

b) Communication: We may use your email address to send you important updates, notifications, and promotional messages regarding the Coperinno App. You can opt-out of receiving promotional emails at any time.

c) Analytics and Advertising: We may analyze the collected data to understand user behavior, preferences, and trends. This information helps us improve our services and display relevant advertisements within the Coperinno App.

3. Disclosure of Information:
We may disclose your personal information in the following circumstances:

a) Service Providers: We may engage third-party service providers to assist us in operating the Coperinno App, such as hosting, data analysis, and customer support. These service providers are contractually bound to handle your personal information with utmost confidentiality.

b) Legal Requirements: We may disclose your personal information if required by law, court order, or government regulation to protect our rights or the safety of others.

c) Merger or Acquisition: In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction.

4. Security:
We employ industry-standard security measures to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.

5. Your Choices:
a) Account Information: You can review and update your account information by accessing the settings within the Coperinno App.

b) Opting Out: You have the right to opt-out of receiving promotional emails from us. You can do so by following the unsubscribe instructions provided in the email or by contacting us directly.

c) Data Retention: We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Consent Document, unless a longer retention period is required or permitted by law.

6. Children's Privacy:
The Coperinno App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you believe we have inadvertently collected information from a child under 13, please contact us immediately, and we will take steps to delete the information.

7. Changes to this Document:
We reserve the right to update or modify this Privacy Consent Document at any time. If we make material changes, we will notify you by posting the revised document on the Coperinno App or through other communication channels.

8. Contact Us:
If you have any questions, concerns, or requests regarding this Privacy Consent Document or our privacy practices.

By using the Coperinno App, you acknowledge that you have read and understood this Privacy Consent Document and consent to the collection, use, and disclosure of your personal information in accordance with its terms.


            </p>

        </div>
    )
}


