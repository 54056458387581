import React, {useEffect, useState} from 'react';
import { postContentWidth, SpecialActionColor } from '../utils/consts';

export function shuffle(productsArray) {
    if (Array.isArray(productsArray)){
        let currentIndex = productsArray.length,  randomIndex;
        // While there remain elements to shuffle.
        while (currentIndex !== 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [productsArray[currentIndex], productsArray[randomIndex]] = [
                productsArray[randomIndex], productsArray[currentIndex]];
        }

        return productsArray;

    }
    return [];
}

export const renderClickableLinks = (word, index) => {
    if (word.startsWith('https://')) {
      return (
        <a key={index} href={word} style={{textDecoration: 'underline'}}target="_blank" rel="noopener noreferrer">
          {word}
        </a>
      );
    }
    return word;
  };
export const renderParagraph = (text) => {
    return text.split(/\s+/).map((word, index) => (
      <React.Fragment key={index}>
        {index > 0 && ' '}
        {renderClickableLinks(word, index)}
      </React.Fragment>
    ));
  };
  
export const renderText = (text) => {
    return text.split('\n').map((word, index) => (
      <p style={{fontFamily: 'sans-serif'}} key={index}>
        {renderParagraph(word, index)}
      </p>
    ));
  };


export function testIsRTL(input) {
  if (input) {
    // Remove all non-letters and spaces, then find the first letter
    const firstLetter = input.replace(/[^a-zA-Z\u0600-\u06FF\u0590-\u05FF]+/g, '')[0];
    // Test if the first letter belongs to the RTL character sets
    return /^[\u0600-\u06FF\u0590-\u05FF]/.test(firstLetter);
  }
  return false;
}

export const redirectToApp = () => {
  const isiOS = () => /Macintosh|iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isSafari = () => /Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent);
  const androidAppURL = 'https://play.google.com/store/apps/details?id=com.danielbny.coperinnoapp';
  const iOSAppURL = 'https://apps.apple.com/us/app/coperinno/id1666058657?platform=iphone';
  if (isSafari()) 
  {
    window.location.href = iOSAppURL;
  }
  else if (isiOS()) {
    window.location.href = iOSAppURL;
  } else {
    window.location.href = androidAppURL;
  }
};


export const redirectToGroupPage = (groupID, groupName, groupDescription, inviteCode) => {
  const url = `coperinno://circle-invite/${groupID}/${inviteCode}`;
  window.location.href = url;
};


export const redirectToPageToApp = (suffix) => {
  window.location.href = 'https://coperinno.com/app/'+suffix;
};
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function DownloadPart() {
  // const [choosenOption, setOption] = useState({});
  // useEffect(()=>{
  //   var options = [
  //     {'title': 'Open Coperinno', 'suffix': 'opencoperinno'},
  //   ]
  //   let rand = getRandomInt(options.length);
  //   setOption(options[rand])
  // }, [])
  return(
    <div>
      <div style={styles.appFooter}>
      <button style={styles.largeButton} onClick={() => redirectToPageToApp('opencoperinno')}>
        <span style={styles.buttonText}>{'Open Coperinno'}</span>
      </button>
      </div>
    </div>
  )
  }


  export const ResponsiveImage = ({ media }) => {
    const isPhone = window.innerWidth < 1000;
    const isVertical = media.height > media.width;
    let dynamicStyles = {};
    if (isPhone) {
      dynamicStyles = {
        height: (media.height/media.width)*postContentWidth, 
        width: postContentWidth, 
        marginBottom: '20px',
    };
    } else if (isVertical) {
      dynamicStyles = {
        width: 'auto', 
        height: postContentWidth*0.5,
        marginBottom: '20px',
      };
    } else {
      dynamicStyles = {
        width: postContentWidth*0.5,
        height: 'auto',
        marginBottom: '20px',
      };
    }
    return (
      <img src={media.imageURI} alt="" style={dynamicStyles} />
    );
}

const styles = {
    DownloadText: {
      fontFamily: 'sans-serif', 
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: '10px',
      lineHeight: '1.75', // Adjust the line height for spacing between lines
    },
    appFooter: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: '#fff', // You can set the background color as needed
      padding: '15px', // Adjust padding as needed
      textAlign: 'center',
    },
    downloadButton: {height: '30px',  margin: '0 10px'},
    largeButton: {
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
      borderRadius: '50px',
      backgroundColor: SpecialActionColor,
      color: 'white',
      fontSize: '16px',
      fontWeight: 'bold',
      border: 'none',
      cursor: 'pointer',
      margin: '0 auto', 
    },
    buttonText: {
      textAlign: 'center',
    },
};
  