import React from 'react';
import { Helmet } from 'react-helmet';
import { DownloadPart } from '../utils/utils';
import appimages from '../images/appimages.png';
import europeimage from '../images/europe.jpeg';


const BlogPost1 = () => {
  const styles = {
    blogPost: {
      fontFamily: 'Roboto, sans-serif',
      margin: 0,
      padding: 20,
      marginBottom:100,
      maxWidth: '800px',
      margin: 'auto',
    },
    blogImage: {
      width: '100%',
      height: 'auto',
      display: 'block',
      marginBottom: '20px',
    },
    internalblogImage: {
      width: '50%',
      height: 'auto',
      display: 'block',
      marginBottom: '20px',
      textAlign: 'center',
    },
    smallInsideImage: {
      width: '50%',
      height: 'auto',
      display: 'block',
      marginBottom: '20px',
    },
    blogTitle: {
      fontSize: '2rem',
      marginBottom: '20px',
      color: '#333',
    },
    blogSubtitle: {
      fontSize: '1.4rem',
      marginTop: '20px',
      marginBottom: '10px',
      color: '#555',
    },
    blogParagraph: {
      fontSize: '1rem',
      lineHeight: '1.6',
      marginBottom: '20px',
      color: '#666',
    },
  };

  return (
    <div style={styles.blogPost}>
      <Helmet>
        <title>Blogging on Mobile | Start Blogging Today from Your Phone</title>
        <meta name="description" content="Start blogging on your phone with Coperinno. Share your travel adventures, life stories, lifestyle tips, and recipes with ease. Download now and begin your blogging journey!" />
        <meta name="keywords" content="blogging on mobile, start blogging, travel blog, lifestyle blog, recipe blog, Coperinno" />
        <meta property="og:title" content="Blogging on Mobile | Start Blogging Today from Your Phone" />
        <meta property="og:description" content="Start blogging on your phone with Coperinno. Share your travel adventures, life stories, lifestyle tips, and recipes with ease. Download now and begin your blogging journey!" />
        <meta property="og:url" content="https://coperinno.com/blog/blogging-on-mobile-start-blogging-today" />
      </Helmet>

      <main>
        <section>
          <img src={appimages} alt="Start Blogging Today" style={styles.blogImage} />
          <h1 style={styles.blogTitle}>Blogging on Mobile</h1>
          <p style={styles.blogParagraph}>You might think of blogging as something you do on a computer, but guess what? With <strong>Coperinno</strong>, you can blog right from your phone! It’s perfect for capturing your thoughts and ideas whenever inspiration strikes.</p>
          <h3 style={styles.blogSubtitle}>Why You’ll Love Coperinno</h3>
          <p style={styles.blogParagraph}>Coperinno is a free app that is all about keeping things simple. Our app features easy-to-use templates that let you add paragraphs, images, and titles in just a few taps. Whether you’re a newbie or a pro, Coperinno makes blogging a breeze. Just download, set up your profile, and you’re ready to share your first post!</p>

          <h3 style={styles.blogSubtitle}>Traveling Through Europe? Share Your Epic Journey</h3>
          <p style={styles.blogParagraph}>Capture your journey by creating a travel journal with your experiences. Add beautiful photos and document each day’s adventures with ease.</p>
          <img src={europeimage} alt="Start Blogging Today" style={styles.blogImage} />

          <h3 style={styles.blogSubtitle}>Got Life Stories to Share?</h3>
          <p style={styles.blogParagraph}>Got something to say? Whether it’s deep thoughts or daily experiences, Coperinno lets you express yourself through engaging, long-form posts. Add cool images and eye-catching titles to make your stories pop and keep your followers hooked.</p>

          <h3 style={styles.blogSubtitle}>Creating a Lifestyle Blog? Let’s Do It!</h3>
          <p style={styles.blogParagraph}>Want to show off your unique lifestyle? Coperinno makes it super easy to share your daily experiences and favorite tips. Post about what you’re up to, share recommendations, and add awesome pics to keep your friends and followers in the loop.</p>

          <h3 style={styles.blogSubtitle}>Got Recipes You’re Obsessed With?</h3>
          <p style={styles.blogParagraph}>Turn your recipe stash into a stunning blog with Coperinno. Organize your favorite dishes, upload step-by-step photos, and share your culinary creations with everyone. Your friends and family will be amazed by your cooking skills!</p>

          <p style={styles.blogParagraph}><strong>Download Coperinno now and start sharing your stories. We can't wait to see your first post!</strong></p>
        </section>
      </main>
      <DownloadPart/>
    </div>
  );
};

export default BlogPost1;
