import React, {useState, useEffect} from "react";
import { Helmet } from 'react-helmet';
import { SERVER_URL } from '../utils/consts';
import { redirectToApp, testIsRTL, redirectToGroupPage } from '../utils/utils';
import { useParams } from 'react-router-dom';

const GroupInvitePage  = () => {
    const { groupid, invitecode } = useParams();
    const [groupInfo, setGroupInfo] = useState(null);
    console.log(groupInfo);
    useEffect(() => {
        const fetchGroupInfo = async () => {
            try {
                const response = await fetch(SERVER_URL+`/get_group_basic_info_for_invite/${groupid}/${invitecode}/`);
                if (response.ok) {
                    const data = await response.json();
                    setGroupInfo(data);
                } else {
                    console.error('Failed to fetch group info');
                }
            } catch (error) {
                console.error('Error fetching group info:', error);
            }
        };

        fetchGroupInfo();
    }, [groupid, invitecode]);
    return (
        <div style={styles.landing}>
          <div style={styles.groupImageContainer}>
            <img 
              src={SERVER_URL+`/group_image/${groupid}/${invitecode}/`} 
              alt={groupInfo?.name} 
              style={styles.groupImage}
            />
          </div>
          <div style={styles.contentContainer}>
            <header style={{...styles.landingHeader, ...styles.landingParagraph}}>
              <h1 style={{
                    fontSize: '35px',
                    margin: '10px',
                    textAlign: testIsRTL(groupInfo?.name) ? 'right' : 'left',
                    direction: testIsRTL(groupInfo?.name) ? 'rtl' : 'ltr',
                }} >{groupInfo?.name}</h1>
              <h2 style={{
                      fontSize: '20px',
                      margin: '10px',
                      textAlign: testIsRTL(groupInfo?.description) ? 'right' : 'left',
                      direction: testIsRTL(groupInfo?.description) ? 'rtl' : 'ltr',
               }}>{groupInfo?.description}</h2>
              <h3 style={styles.sidecomment}>To Join Circle first download & signup</h3>

            </header>
            <div style={styles.ctaContainer}>
              <button 
                style={styles.joinCircleButton}
                onClick={() => redirectToGroupPage(groupid, groupInfo?.name, groupInfo?.description, invitecode)}>
                JOIN CIRCLE
              </button>
            </div>
            <div style={styles.ctaContainer}>
              <button 
                style={styles.downloadAppButton}
                onClick={() => redirectToApp()}>
                First Download
              </button>
            </div>

          </div>
        </div>
      );
    };
    

const styles = {
    landing: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    groupImageContainer: {
        width: '100%',
        maxWidth: '200px',
        height: '200px',
        borderRadius: '50%',
        overflow: 'hidden',
        marginBottom: '10px',
    },
    groupImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
    },
    groupName: {
        fontSize: '50px',
        margin: '10px',
    },
    groupDescription: {
      fontSize: '20px',
      margin: '10px',
  },
  sidecomment: {
    fontSize: '18px',
    marginTop: '20px',
    margin: '10px',
  },
  landingParagraph: {
        fontSize: '1.5rem',
        color: 'black',
    },
    ctaContainer: {
        marginTop: '2rem',
    },
    joinCircleButton: {
        backgroundColor: '#5b38ff',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        fontSize: '1.3rem',
        width: '300px',
        borderRadius: '40px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    downloadAppButton: {
      backgroundColor: 'black',
      color: 'white',
      border: 'none',
      padding: '10px 20px',
      fontSize: '1.3rem',
      width: '250px',
      borderRadius: '40px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
  },
    ctaSpan: {
        display: 'block',
        marginTop: '10px',
        fontSize: '0.9rem',
        color: '#666',
    },
    };
export default GroupInvitePage
