import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import {DownloadPart, testIsRTL } from '../utils/utils';
import { postContentWidth, websiteURL, MIN_VIEWS } from '../utils/consts';
import ProfileModal from './ProfileModal';

import { Helmet } from 'react-helmet';

import PostContent from './PostContent';

export const PostDetailsPage = () => {
  const { postId } = useParams();
  const [post, setPost] = useState({});
  const [isVertical, setIsVertical] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  const handleMainImageLoaded = () => {
    setIsLoading(false);
  };

  var postSubtitle = post.post_subtitle || '';
  if (postSubtitle){
    postSubtitle = ' '+postSubtitle;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    const requestOptions = {
      method: 'POST',
      headers: { 'accept': 'application/json' },
      Origin: 'coperinno.com'
    };
    fetch('https://server.coperinno.com/get-post/?post_id=' + postId, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setPost(data);
        var isVertical = data.height > data.width;
        setIsVertical(isVertical);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, [postId]);
  if (!post){
    return <div style={styles.container}>
                <h1 style={{
            fontFamily: 'sans-serif',
            fontSize: '30px',
            marginBottom: '40px',
          }}>
            Oops.. the post does exist</h1>
            <h1 style={styles.text}>Discover more on Coperinno: </h1>

            <DownloadPart> </DownloadPart>
    </div>
  }
  return (
    <div >
      <Helmet>
        <title>{post.post_title + postSubtitle}</title>
        {post.post_paragraphs &&  <meta name="description" content={post.post_paragraphs[0].text.slice(0, 195)+"..."} />}
        <link rel="canonical" href={websiteURL+'/posts/' + postId} />
      </Helmet>
  <div style={styles.container}>

    {isLoading && <div style={{height: 5*window.innerHeight}}>
      Loading...
      </div>}
      <img
          onLoad={handleMainImageLoaded}
          src={"https://server.coperinno.com/image/" + post.image_path}
          alt={post.post_title}
          style={isVertical ?  styles.verticalImage : styles.horizontalImage }
        />

      <div style={styles.content}>
        <div style={styles.postInfo}>
          <h1 style={{
            fontFamily: 'sans-serif',
            textAlign: testIsRTL(post.post_title) ? 'right' : 'left',
            direction: testIsRTL(post.post_title) ? 'rtl' : 'ltr',
            fontSize: '30px',
            marginBottom: '40px',
          }}>
            {post.post_title}
            </h1>
            {post.post_subtitle && 
            <h3 style={{
              fontFamily: 'sans-serif',
              textAlign: testIsRTL(post.post_subtitle) ? 'right' : 'left',
              direction: testIsRTL(post.post_subtitle) ? 'rtl' : 'ltr',
              fontSize: '20px',
              marginBottom: '40px',
              fontWeight: 'normal',
            }}>
              {post.post_subtitle}
              </h3>}

          <div style={styles.info}>
            <p>{post.views > MIN_VIEWS ? `${post.views}  views - ` : ''} {post.time_difference_string} ago</p>
          </div>
  <Link to={'/user/'+post.user_name}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '10px',
    }}>
      <div>
       { post.user_id ? (<img
          src={"https://server.coperinno.com/profile_image/" +post.user_id}
          alt="User Profile"
          style={styles.userImage}
          onError={(e) => { e.target.style.display = 'none'; }}
        />) : null}
      </div>
      <div>
        <p style={{
          marginLeft: '10px',
          fontSize: '15px',
          fontFamily: 'sans-serif',
        }}>By {post.display_name}</p>
        <p style={{
          marginLeft: '10px',
          fontSize: '15px',
          fontFamily: 'sans-serif',
        }}>{post.user_bio}</p>
      </div>
    </div>
    </Link>

    {post.post_paragraphs && post.post_paragraphs.length > 0 ? (

  <PostContent data={post}> </PostContent>

) : (
  <p style={styles.text}>No content available</p>
)}      

  </div>
  {post.user_name && <ProfileModal open={open} handleClose={handleClose} userName={post.user_name} userImage={"https://server.coperinno.com/profile_image/" +post.user_id}/>}

  </div>
    <DownloadPart> </DownloadPart>
    </div>
  </div>
  );
};

const styles = {
  userImage: {
    width: "50px",
    height: "50px",
    objectFit: 'cover',
    borderRadius: "50px",
    border: '0.5px solid black',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    width: postContentWidth,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  verticalImage: {
    width: 'auto', 
    height: window.innerHeight*0.8,
    marginBottom: '20px',
  },
  horizontalImage: {
    width: postContentWidth,
    height: 'auto',
    marginBottom: '20px',
  },
  postInfo: {
    fontFamily: 'sans-serif', 
    flex: 1,
    textAlign: 'left',
  },
  info: {
    fontSize: '15px',
    marginBottom: '10px',
  },
  text: {
    fontFamily: 'sans-serif', 
    fontSize: '18px',
    marginBottom: '10px',
    lineHeight: '1.75',
  },
  downloadLinks: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    padding: '10px',
    textAlign: 'center',
  },
  downloadButton: {
    width: '100px',
    margin: '0 10px', 
  },
};

export default PostDetailsPage;
