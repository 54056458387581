import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Main';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyA07RIAPghzn1TU4Dp2WLODtV-SaUQI3-0",
    authDomain: "shopping-dresses.firebaseapp.com",
    projectId: "shopping-dresses",
    storageBucket: "shopping-dresses.appspot.com",
    messagingSenderId: "460982048879",
    appId: "1:460982048879:web:3dd42a3c96a044d457959a",
    measurementId: "G-2232EV3MP0"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
