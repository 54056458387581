import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { redirectToPageToApp } from '../utils/utils';

import { SpecialActionColor } from '../utils/consts';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '20px'
};

const ProfileModal = ({ open, handleClose, userName, userImage }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
      {userImage && <img
          onError={(e) => { e.target.style.display = 'none'; }}
          src={userImage}
          alt="Profile"
          style={{ objectFit:'cover', borderRadius: '50%', height: '100px', width: '100px', marginBottom: '10px' }}
        />}
        <h3 id="modal-title">See full profile in the app</h3>
        <p style={{ marginTop: '16px', color: 'gray', marginBottom: '10px' }}>
          Enjoy posts, stories and more from {userName}
        </p>
        <button
        onClick={() => redirectToPageToApp('popup')}
          style={{
            borderRadius: '10px',
            width: '250px',
            backgroundColor: SpecialActionColor,
            marginTop: '16px',
            padding: '10px',
            border: 'none',
            color: 'white',
            textTransform: 'none',
            cursor: 'pointer',
            fontSize: 14
          }}
        >
          Open Coperinno
        </button>
      </Box>
    </Modal>
  );
};

export default ProfileModal;