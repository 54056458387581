import React, { useEffect } from 'react';
import { redirectToApp } from '../utils/utils';

const ApplicationStoreRedirectPage = () => {
  useEffect(() => {
    redirectToApp();
  }, []);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default ApplicationStoreRedirectPage;
